import axios from 'axios'
import dayjs from 'dayjs'
import { navigate } from 'gatsby'
import React, { useState, useEffect } from 'react'
import { FacebookShareButton, LinkedinShareButton } from 'react-share'
import styled from 'styled-components'
import { Date } from '../../components/Cards/FeaturedArticle'
import FacebookIcon from '../../components/Icons/FacebookIcon'
import LinkedIcon from '../../components/Icons/LinkedIcon'
import Site from '../../components/Layout/Site'
import MoreArticles from '../../components/PressReleases/MoreArticles'
import DefaultVector from '../../components/Shared/DefaultVector'
import { Title } from '../../components/Shared/SectionHeader.styles'
import { Card, CardFooter } from '../../components/UI/Card'
import { DynamicContent } from '../../components/UI/DynamicContent'
import Element from '../../components/UI/Element'
import { Col, Container, Row } from '../../components/UI/Grid'
import { Section } from '../../components/UI/Section'
import theme from '../../utils/theme'

const PressReleaseDetails = ({ location }) => {
  const [processing, setProcessing] = useState(true)
  const [article, setArticle] = useState(null)
  const [articles, setArticles] = useState([])
  const url = typeof window !== 'undefined' ? window.location.href : ''

  const isBrowser = typeof window !== 'undefined'

  useEffect(() => {
    axios
      .get(
        `${
          isBrowser && window?.location?.origin === 'https://fastech.digiteer.dev'
            ? process.env.GATSBY_STAGING_API_URL
            : process.env.GATSBY_API_URL
        }/news${location.pathname.replace('press-releases', '')}`,
      )
      .then(res => {
        if (res.data) {
          setArticle(res.data)
        } else {
          navigate('/404')
        }
      })
      .finally(() => setProcessing(false))
  }, [location?.pathname])

  useEffect(() => {
    axios
      .get(
        `${
          isBrowser && window?.location?.origin === 'https://fastech.digiteer.dev'
            ? process.env.GATSBY_STAGING_API_URL
            : process.env.GATSBY_API_URL
        }/news`,
      )
      .then(res => {
        setArticles(res.data)
      })
      .finally(() => setProcessing(false))
  }, [])

  if (processing) {
    return null
  }

  return (
    <Site>
      <Section py={{ _: 16, lg: 0 }}>
        <DefaultVector>
          <Container>
            <Row justifyContent="center">
              <Col col={{ lg: 10 }}>
                <TitleWrapper>
                  <Date>{dayjs(article.published_date).format('MMMM YYYY')}</Date>
                  <Title mb={10}>{article.title}</Title>
                </TitleWrapper>

                <Card cardhidden mb={10}>
                  <img src={article.photo_url} alt="banner" />
                  {article.caption?.length > 0 && (
                    <CardFooter bg="brand" color="white" fontWeight="semibold">
                      {article.caption}
                    </CardFooter>
                  )}
                </Card>

                <DynamicContent dangerouslySetInnerHTML={{ __html: article.content }} />
              </Col>
            </Row>
          </Container>
        </DefaultVector>
      </Section>
      {!processing && <MoreArticles id={article.id} articles={articles} />}
    </Site>
  )
}

const TitleWrapper = styled.div`
  padding-left: 20px;
  border-left: 4px solid ${theme.colors.brand};
  color: ${theme.colors.brand};
`

export default PressReleaseDetails

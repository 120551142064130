import React from 'react'
import NewsCard from '../Cards/NewsCard'
import { Title } from '../Shared/SectionHeader.styles'
import Carousel from '../UI/Carousel'
import { Container } from '../UI/Grid'
import { Section } from '../UI/Section'

const MoreArticles = ({ id, articles }) => {
  const otherArticles = articles?.slice(0, 5).filter(i => i.id !== id)

  const moreArticlesSettings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <Section bg="gray" py={{ _: 10, lg: 20 }}>
      <Container>
        <Title textAlign="center" mb={10}>
          More Articles
        </Title>

        <Carousel settings={moreArticlesSettings} className="slick-spacing slick-equal">
          {otherArticles?.map(a => (
            <NewsCard
              key={a.article_id}
              bg="white"
              title={a.title}
              slug={`/press-releases/${a.slug}`}
              excerpt={a.excerpt}
              thumbnail={a.photo_url}
              date={a.published_date}
            />
          ))}
        </Carousel>
      </Container>
    </Section>
  )
}

export default MoreArticles
